import React from "react"
import styled from "styled-components"

const StagingContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  cursor: pointer;

  img {
    position: absolute;
    bottom: -490px;
    right: -20px;
    z-index: -1;
    transition: bottom 0.2s ease-out;
  }

  &:hover {
    img {
      position: absolute;
      bottom: -370px;
      transition: bottom 0.2s ease-in;
    }
  }
`

const Inner = styled.div`
  background-color: ${props =>
    props.alternate
      ? props.theme.colors.tertiary
      : props.theme.colors.secondary};
  color: ${props => props.theme.colors.admin};
  padding: 1rem 2rem;
  font-size: 1.3rem;
`

const UtilityBar = () => {
  if (window.RAILS_ENV == "staging") {
    return (
      <StagingContainer>
        <img src="https://yarno.imgix.net/static/customer-service-eggers.png?format=auto,compress&w=200&flip=h" />
        <Inner>You are in staging</Inner>
      </StagingContainer>
    )
  }
  return null
}

export default UtilityBar
