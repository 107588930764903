import styled from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

export const MenuHamburger = styled.button`
  ${props =>
    props.isFixed &&
    `position: fixed;
  top: 0;
  right: 0; `}
  z-index: ${zIndex.menu + 1};

  padding: 15px 15px 6px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: 0;
  }
`

export const HamburgerBox = styled.span`
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;

  span {
    display: block;
    top: 2px;
    margin-top: -2px;
    ${props =>
      props.isActive &&
      `
      -webkit-transform: translate3d(0, 8px, 0) rotate(45deg);
      transform: translate3d(0, 8px, 0) rotate(45deg);
    `}
  }

  span,
  span:before,
  span:after {
    width: 24px;
    height: 2px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  span:before,
  span:after {
    content: "";
    display: block;
  }
  span:before {
    top: 8px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.2s;
    ${props =>
      props.isActive &&
      `
      -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0;
    `}
  }
  span:after {
    bottom: -8px;
    top: 16px;
    ${props =>
      props.isActive &&
      `
      -webkit-transform: translate3d(0, -16px, 0) rotate(-90deg);
      transform: translate3d(0, -16px, 0) rotate(-90deg);
    `}
  }
`
